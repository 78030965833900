import React, { Component } from 'react';
import util from '../util';
import './Products.css';

export default class Products extends Component {

    render() {
        const productItems = this.props.products.map(product => {
            let estilo = {
                minHeight: '200px',
                backgroundImage: ` url('products/${product.sku}_2.jpg')`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            };
            
            return (
                <div className="col-md-3" key={product.id}>
                    <div className="thumbnail item-modal text-center">
                        <a 
                         href={`#${product.id}`}
                        onClick={(e)=>this.props.selectProductInfo(product)}>
                            {/* <img src={`products/${product.sku}.jpg`} alt={product.title} /> */}
    
                            <div style={estilo}>
                            </div>
                            <p>{product.title}</p>                        
                        </a>
                        <b>{util.formatCurrency(product.price)}</b>
                        <button className="mais-info btn btn-primary" onClick={(e)=>this.props.selectProductInfo(product)}>Mais Informações</button>
                    </div>
                </div>
            );
        });

        return (
            <div className="row">
                {productItems}
            </div>
        )
    }
}
