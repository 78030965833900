import React, { Component } from "react";
import './ProductInfo.css';



// Todo produto com types, o index 0 é uma copia do produto default 
// entao o index 0 é sempre igual ao produto em si clicado


class ProductInfo extends Component {
    constructor(props) {
        super();
    }

    render() {
        const { product, active, index } = this.props;
        const productSelected = product.types && (product.types.length && index && product.types[this.props.index] || product.types[0]) || product;
        console.log(product);
        return (
            <div class="product-info" style={
                {
                    backgroundColor: "white",
                    display: this.props.active ? 'block' : 'none',
                }
            }>
            
            
                {(product.types && product.types.length > 0 ?
                    <div className="product-info-menu">
                    <div className="selecionar-tipo">
                        Selecionar Tipo:
                    </div>
                    
                        <ul>
                            {product.types.map((tipo, index) => {
                                return (
                                    <li key={index}>
                                        <button onClick={() => this.props.changeIndex(index)}>
                                            {tipo.title}
                                        </button>
                                    </li>)
                            })}
                        </ul>
                    </div>
                    : ''
                )}
                <div className="product-selected">
                    <div className="productData">
                        <div className="image-product">
                            <img src={`products/${productSelected.sku}_2.jpg`} />
                        </div>

                        <div className="title">
                            <h2>
                                {productSelected.title}
                            </h2>

                                {productSelected.description}

                            <span>
                                Preço: R$ {productSelected.price}
                            </span>

                        
                        {
                            productSelected.kg ?
                                (
                                        <span>
                                            Peso: {productSelected.kg} Kg
                                        </span>
                                )
                                :
                                ''
                        }

                        </div>

                        <div className="add-to-cart" onClick={(e) => {
                            this.props.handleAddToCart(e, productSelected);
                            this.props.fecharProductInfo();
                        }}>
                            Adicionar ao Carrinho
                        </div>

                    </div>
                </div>

                <div className="botao-fechar" onClick={() => {
                    return this.props.fecharProductInfo();
                }}>
                    X
            </div>
            </div>
        );
    }
}


export default ProductInfo;