import React, { Component } from 'react';


import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import './App.css';
import ListProducts from './components/ListProducts';
import HomePage from './components/HomePage';
import Checkout from './components/Checkout';
import Shipping from './components/Shipping';

class App extends Component {
  constructor() {
    super();
    this.state = { size: '', sort: '', cartItems: [], products: [], filteredProducts: [] };
  }
  

  render() {
    return (

      <Router>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/list-products" component={ListProducts} />
          <Route exact path="/checkout" component={Checkout} />
          <Route exact path="/shipping" component={Shipping} />
        </Switch>
      </Router>


      // <div className="container">
      //   <ListProducts
      //     filteredProducts ={this.state.filteredProducts}
      //     cartItems ={this.state.cartItems}

      //     handleSortChange={this.handleSortChange}
      //     handleAddToCart={this.handleAddToCart}
      //     handleRemoveFromCart={this.handleRemoveFromCart}
      //   />

      // </div>
    );
  }
}

export default App;
