import React, { Component } from 'react';
import './Filter.css'


export default class Products extends Component {

    render() {


        return (
            <div className="row">
                <div className="ordenar">
                    <label className="orderna-titulo">Ordenar por</label>
               <select className="form-control" value={this.props.sort} onChange={this.props.handleSortChange}>
                            <option value="">Selecionar</option>
                            <option value="lowestprice">Menor Preço</option>
                            <option value="highestprice">Maior Preço</option>
                        </select>
                    
                </div>
                {/* <div className="col-md-4">
                    <label > Filter Size
               <select className="form-control" value={this.props.size} onChange={this.props.handleSizeChange}>
                            <option value="">ALL</option>
                            <option value="x">XS</option>
                            <option value="s">S</option>
                            <option value="m">M</option>
                            <option value="l">L</option>
                            <option value="xl">XL</option>
                            <option value="xxl">XXL</option>
                        </select>
                    </label>
                </div> */}
            </div>
        )
    }
}
