import React, { Component } from 'react';
import util from '../util'
import { Link } from "react-router-dom";
import './BasketMobile.css';

export default class BasketMobile extends Component {
    render() {
        const { cartItems } = this.props;

        return (
            <div className="basket-mobile">
                {/* {cartItems.length === 0
                ? (<div className="info-carrinho">
                    <h3>Seu carrinho esta vazio!</h3>
                <h4>Seus itens aparecerão aqui quando forem adicionados ao carrinho</h4>
                <h5 className="observacao-kg">Caso queira alterar a quantidade de kg's do produto, poderá especificar quando entrarmos em contato com você</h5>
                </div>) :
                    <div className="info-carrinho">Você tem {cartItems.length} itens no seu carrinho. <hr /></div>
                } */}
                {cartItems.length > 0 &&
                    <div className="itens-carrinho-mobile">
                        <p className="valor-total-mobile"><h4>Total: {util.formatCurrency(cartItems.reduce((a, c) => (a + c.price * c.count), 0))}
                        </h4>
                        </p>
                        {
                            this.props.buttonFinalizar ?
                            <Link className="botao-finalizar-mobile" to="/shipping">Finalizar Compra</Link>
                            : ''
                        }
                        
                    </div>
                }
            </div>
        )
    }
}
