import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import React, { Component } from 'react';

export class GoogleMaps extends Component {
  render() {

    var points = [
        { lat: -23.524371, lng: -46.686090 },
        { lat: -23.524381, lng: -46.686090 },
        { lat: -23.524375, lng: -46.686090 },
        { lat: -23.524393, lng: -46.686090 },
        { lat: -23.524310, lng: -46.686090 },
    ];

    var bounds = new this.props.google.maps.LatLngBounds();
    for (var i = 0; i < points.length; i++) {
        bounds.extend(points[i]);
    }


    return (

        
      <Map 
      google={this.props.google}
      zoom={14}
      initialCenter={{
        lat: -23.524370,
        lng: -46.686090
      }}
    //   bounds={bounds}>
      >

        <Marker onClick={this.onMarkerClick}
                name={'Current location'} />

        <InfoWindow onClose={this.onInfoWindowClose}>
            <div>
              <h1>dasdasdasd</h1>
            </div>
        </InfoWindow>
      </Map>
    );
  }
}

export default GoogleApiWrapper({
    apiKey: ('AIzaSyD-3YmTTQUs21BbrXe-pBjs-e_MEpvGqq4')
  })(GoogleMaps);