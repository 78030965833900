import React, { Component } from 'react';
import util from '../util'
import { Link } from "react-router-dom";
import './Basket.css';

export default class Basket extends Component {
    render() {
        const { cartItems, preco } = this.props;

        return (
            <div className="carrinho">
                {cartItems.length === 0
                ? (<div className="info-carrinho">
                    <h3>Seu carrinho esta vazio!</h3>
                <h4>Seus itens aparecerão aqui quando forem adicionados ao carrinho</h4>
                <h5 className="observacao-kg">Caso queira alterar a quantidade de kg's do produto, poderá especificar quando entrarmos em contato com você</h5>
                </div>) :
                    <div className="info-carrinho">Você tem {cartItems.length} itens no seu carrinho. <hr /></div>
                }
                {cartItems.length > 0 &&
                    <div className="itens-carrinho">
                        <ul style={{ marginLeft: -25 }}>
                            {cartItems.map(item => (
                                <li key={item.id}>
                                    <b>{item.title}</b>
                                    <button style={{ float: 'right' }} className="btn btn-danger btn-xs"
                                        onClick={(e) => this.props.handleRemoveFromCart(e, item)}>X</button>
                                    <br />
                                    {item.count} X {util.formatCurrency(item.price)}
                                </li>))
                            }
                        </ul>

                        <p className="valor-total"><h4>Total: {util.formatCurrency(cartItems.reduce((a, c) => (a + c.price * c.count), 0))}
                        </h4>

                        {
                            this.props.pegarEncomenda && this.props.pegarEncomenda == '1' ?
                                <p className="valor-total">R$0 - Pegar encomenda</p>
                            :
                                <p className="valor-total"><h6 class="entrega">Taxa de entrega: R${preco ? preco : 35}</h6> </p>
                        }
                         </p>
                        
                       
                        {
                            this.props.buttonFinalizar ?
                            <Link className="botao-finalizar" to="/shipping">Finalizar Compra</Link>
                            // <Link className="botao-finalizar" to="/checkout">Finalizar Compra</Link>
                            : ''
                        }
                        
                    </div>
                }
            </div>
        )
    }
}
